import AuthService from "../services/auth.service";
import FileService from "../services/files.services";

export const auth = {
  namespaced: true,
  state: {
    account: {},
    siteId: String,
    subSiteId: String,
    driveId: String,
  },
  mutations: {
    setAccount(state, account) {
      state.account = account;
    },
    logout(state) {
      state.accout = undefined;
    },
    setSiteId(state, siteId) {
      state.siteId = siteId;
    },
    setSubSiteId(state, siteId) {
      state.subSiteId = siteId;
    },
    setDriveId(state, driveId) {
      state.driveId = driveId;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, username) {
      AuthService.login(username);
    },
    // eslint-disable-next-line no-unused-vars
    async onSuccessLogin({ dispatch, commit }, account) {
      commit("setAccount", account.account);
      FileService.getUserSite(account.account.username).then((response) => {
        commit("setSiteId", response.id);
        FileService.getUserFirstSubSite(
          account.account.username,
          response.id
        ).then((response) => {
          commit("setSubSiteId", response.value[0].id);
          FileService.getUserDriveId(
            account.account.username,
            response.value[0].id
          ).then((response) => {
            commit("setDriveId", response.id);
          });
        });
      });
      // const site =
      // init du site id et drive id

      //router.push("/Home");
    },
    logout({ commit }) {
      AuthService.logout().then(() => {
        commit("logout");
      });
    },
    // eslint-disable-next-line no-unused-vars
    //getUserFiles({ commit }, username) {
    //  AuthService.getUserFiles(username);
    //},
  },
  getters: {
    isLogged(state) {
      //let log = state.account.name != undefined;
      return state.account.name != undefined;
    },
    getAccountName(state) {
      return state.account.name;
    },
    getUserName(state) {
      return state.account.username;
    },
    getDriveId(state) {
      return state.driveId;
    },
  },
};
