import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import Login from "../views/Login.vue";
import Constat from "../views/Constat.vue";
// import GraphTest from "../views/GraphTest.vue";
import Home from "../views/Home.vue";
// import Files from "../views/Files.vue";
import FilesMulti from "../views/FilesMulti.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  // {
  //   path: "/graphtest",
  //   name: "Test",
  //   component: GraphTest,
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/constat",
    name: "Constat",
    component: Constat,
    meta: { requiresAuth: true },
  },
  {
    path: "/files",
    name: "Files",
    // component: Files,
    component: FilesMulti,
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/files-multi",
  //   name: "FilesMulti",
  //   component: FilesMulti,
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters["auth/isLogged"]) {
      next({
        path: "/login",
        // query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
