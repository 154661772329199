import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import store from "../store";
import router from "../router";

//import GraphService from "../graph/graph.service";

const msalConfig = {
  auth: {
    clientId: "c7f6d1a0-5721-4d90-9405-ac322610dfc9",
    authority:
      "https://login.microsoftonline.com/0010e1b3-d2b3-42fe-88ae-aef9494f4fe9",
    //redirectUri: "https://client.huissiers-reunis-mornant.fr/",
    postLogoutRedirectUri: "https://client.huissiers-reunis-mornant.fr/",
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
const loginRequest = {
  scopes: [
    "openid",
    "User.Read",
    "Files.Read.All",
    "Sites.Read.All",
    "Files.ReadWrite.All",
    "Sites.ReadWrite.All",
  ],
  domain_hint: "organizations",
};

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance
  .handleRedirectPromise()
  .then((tokenResponse) => {
    if (tokenResponse !== null) {
      const myAccounts = msalInstance.getAllAccounts();
      const account = myAccounts[0];
      store.dispatch("auth/onSuccessLogin", {
        account: account,
      });
      router.push("/home");
    }
    // Check if the tokenResponse is null
    // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
    // If the tokenResponse === null, you are not coming back from an auth redirect.
  })
  .catch((error) => {
    console.log(error);
  });

class AuthService {
  login(username) {
    loginRequest.loginHint = username;
    try {
      msalInstance.loginRedirect(loginRequest);
    } catch (err) {
      console.log(err);
    }
    /*return await msalInstance
      .loginPopup(loginRequest)
      .then(() => {
        const myAccounts = msalInstance.getAllAccounts();
        return myAccounts[0];
      })
      .catch((error) => {
        console.error(`error during authentication: ${error}`);
      });*/
  }

  async getTokenPopup(loginRequest, username) {
    //console.log(username);
    loginRequest.account = msalInstance.getAccountByUsername(username);
    //console.log(loginRequest);
    return msalInstance.acquireTokenSilent(loginRequest).catch((error) => {
      console.log(
        "silent token acquisition fails. acquiring token using popup"
      );
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance
          .acquireTokenPopup()
          .then((tokenResponse) => {
            //console.log(tokenResponse);
            return tokenResponse;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.warn(error);
      }
    });
  }

  async logout() {
    return await msalInstance
      .logout({})
      .then(() => {
        return "ok";
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export default new AuthService();
