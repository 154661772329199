<template>
  <div class="min-w-screen min-h-screen bg-gray-50">
    <NavBar></NavBar>
    <!-- overflow-y-auto -->
    <main class="container mx-auto flex-1 relative pb-8 z-0 mt-8">
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div
          class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
        >
          <div class="ml-4 mt-2">
            <h2
              class="text-xl md:text-2xl font-bold leading-7 text-gray-500 sm:text-3xl sm:truncate"
            >
              Vos constats
            </h2>
          </div>

          <div class="flex md:ml-4 text-xs md:text-base">
            <span
              class="hidden relative z-0 inline-flex shadow-sm rounded-md mt-2"
            >
              <button
                type="button"
                @click="onSortMenuClick()"
                class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-600 hover:bg-gray-50 focus:z-10"
              >
                Trie
                <svg
                  class="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <span class="-ml-px relative block">
                <button
                  type="button"
                  @click="updateSort()"
                  class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 h-full"
                  id="option-menu-button"
                  aria-expanded="true"
                  aria-haspopup="true"
                >
                  <svg
                    v-show="sortSens == 1"
                    class="mr-3 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z"
                    ></path>
                  </svg>
                  <svg
                    v-show="sortSens == -1"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-3 h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"
                    />
                  </svg>
                </button>

                <!--
      Dropdown menu, show/hide based on menu state.

      Entering: "transition ease-out duration-100"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
      Leaving: "transition ease-in duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
    -->
                <div
                  v-show="showSortMenu"
                  class="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="option-menu-button"
                  tabindex="-1"
                >
                  <div class="py-1" role="none">
                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                    <select v-model="orderBy">
                      <option
                        value="date"
                        v-bind:class="{
                          'bg-gray-100 text-gray-900': sortByDate,
                        }"
                        class="group flex items-center px-4 py-2 text-sm w-full"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        <svg
                          v-bind:class="{
                            'text-orange-500': sortByDate,
                            'text-gray-400': !sortByDate,
                          }"
                          xmlns="http://www.w3.org/2000/svg"
                          class="mr-3 h-5 w-5 group-hover:text-gray-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        Date de mise en ligne
                        <span
                          v-bind:class="{ hidden: !sortByDate }"
                          class="absolute right-0 flex items-center pr-4 text-orange-500"
                        >
                          <svg
                            class="h-5 w-5"
                            x-description="Heroicon name: solid/check"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </span>
                      </option>
                      <option
                        value="name"
                        v-bind:class="{
                          'bg-gray-100 text-gray-900': sortByCity,
                        }"
                        class="text-gray-700 w-full group flex items-center px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          v-bind:class="{
                            'text-orange-500': sortByCity,
                            'text-gray-400': !sortByCity,
                          }"
                          class="mr-3 h-5 w-5 group-hover:text-gray-500"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        Ville
                        <span
                          v-bind:class="{ hidden: !sortByCity }"
                          class="absolute right-0 flex items-center pr-4 text-orange-500"
                        >
                          <svg
                            class="h-5 w-5"
                            x-description="Heroicon name: solid/check"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </span>
                      </option>
                    </select>
                  </div>
                </div>
              </span>
            </span>
            <button
              type="button"
              class="mx-2 md:mx-3 inline-flex items-center border border-transparent bg-orange-500 hover:bg-orange-700 mt-2 px-1 md:px-3 py-1 md:py-2 text-white rounded text-center font-bold"
              v-on:click="refresh()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flex-shrink-0 md:mr-1.5 h-5 w-5 text-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  d="M10 11H7.101l.001-.009a4.956 4.956 0 0 1 .752-1.787 5.054 5.054 0 0 1 2.2-1.811c.302-.128.617-.226.938-.291a5.078 5.078 0 0 1 2.018 0 4.978 4.978 0 0 1 2.525 1.361l1.416-1.412a7.036 7.036 0 0 0-2.224-1.501 6.921 6.921 0 0 0-1.315-.408 7.079 7.079 0 0 0-2.819 0 6.94 6.94 0 0 0-1.316.409 7.04 7.04 0 0 0-3.08 2.534 6.978 6.978 0 0 0-1.054 2.505c-.028.135-.043.273-.063.41H2l4 4 4-4zm4 2h2.899l-.001.008a4.976 4.976 0 0 1-2.103 3.138 4.943 4.943 0 0 1-1.787.752 5.073 5.073 0 0 1-2.017 0 4.956 4.956 0 0 1-1.787-.752 5.072 5.072 0 0 1-.74-.61L7.05 16.95a7.032 7.032 0 0 0 2.225 1.5c.424.18.867.317 1.315.408a7.07 7.07 0 0 0 2.818 0 7.031 7.031 0 0 0 4.395-2.945 6.974 6.974 0 0 0 1.053-2.503c.027-.135.043-.273.063-.41H22l-4-4-4 4z"
                ></path>
              </svg>
              Rafraîchir
            </button>
          </div>
        </div>
      </div>
      <div class="constat-page bg-white">
        <div
          v-for="constat in getConstatFolder"
          :key="constat.ref"
          class="w-full border-b hover:bg-gray-100"
        >
          <input
            type="checkbox"
            name="panel"
            :id="constat.ref"
            class="hidden"
          />
          <div class="px-4 py-4 sm:px-6">
            <label :for="constat.ref">
              <div class="flex items-center justify-between">
                <p class="flex items-center text-lg text-gray-600">
                  <!-- Heroicon name: solid/users -->
                  <svg
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M7 11H9V13H7zM7 15H9V17H7zM11 11H13V13H11zM11 15H13V17H11zM15 11H17V13H15zM15 15H17V17H15z"
                    ></path>
                    <path
                      d="M5,22h14c1.103,0,2-0.897,2-2V8V6c0-1.103-0.897-2-2-2h-2V2h-2v2H9V2H7v2H5C3.897,4,3,4.897,3,6v2v12 C3,21.103,3.897,22,5,22z M19,8l0.001,12H5V8H19z"
                    ></path>
                  </svg>
                  {{ constat.date }}
                </p>
                <div
                  class="mt-2 flex items-center text-base text-gray-500 sm:mt-0"
                >
                  <div
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <p
                    class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                  >
                    {{ constat.ref }}
                  </p>
                </div>
              </div>
              <div class="mt-2 flex justify-between">
                <div class="flex">
                  <p
                    class="flex items-center text-base md:text-lg text-gray-500"
                  >
                    <!-- Heroicon name: solid/users -->
                    <svg
                      class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M12,14c2.206,0,4-1.794,4-4s-1.794-4-4-4s-4,1.794-4,4S9.794,14,12,14z M12,8c1.103,0,2,0.897,2,2s-0.897,2-2,2 s-2-0.897-2-2S10.897,8,12,8z"
                      ></path>
                      <path
                        d="M11.42,21.814C11.594,21.938,11.797,22,12,22s0.406-0.062,0.58-0.186C12.884,21.599,20.029,16.44,20,10 c0-4.411-3.589-8-8-8S4,5.589,4,9.995C3.971,16.44,11.116,21.599,11.42,21.814z M12,4c3.309,0,6,2.691,6,6.005 c0.021,4.438-4.388,8.423-6,9.73C10.389,18.427,5.979,14.441,6,10C6,6.691,8.691,4,12,4z"
                      ></path>
                    </svg>
                    {{ constat.ville }}
                    <span class="text-sm md:text-base ml-2">{{
                      constat.adresse
                    }}</span>
                  </p>
                </div>
                <div class="ml-2 flex flex-shrink-0 text-gray-600">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 arrow-down"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 arrow-up"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 15l7-7 7 7"
                    />
                  </svg>
                </div>
              </div>
            </label>
          </div>
          <div class="accordion__content overflow-hidden bg-grey-lighter">
            <span class="px-4 pb-4 flex space-x-3">
              <button
                type="button"
                class="inline-flex -ml-px relative items-center px-2 py-1 md:px-4 md:py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none"
                v-on:click="downloadVideo(constat)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-1 md:mr-2.5 h-6 md:h-8"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="fill: rgba(237, 137, 54, 1); transform: ; -ms-filter: "
                >
                  <path fill="none" d="M11 14L18 10 11 6z"></path>
                  <path d="M4,8H2v12c0,1.103,0.897,2,2,2h12v-2H4V8z"></path>
                  <path
                    d="M20,2H8C6.896,2,6,2.896,6,4v12c0,1.104,0.896,2,2,2h12c1.104,0,2-0.896,2-2V4C22,2.896,21.104,2,20,2z M11,14V6l7,4 L11,14z"
                  ></path>
                </svg>
                <span>Video</span>
              </button>
              <button
                type="button"
                class="inline-flex -ml-px relative items-center px-2 py-1 md:px-4 md:py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none"
                v-on:click="downloadConstat(constat)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-1 md:mr-2.5 h-6 md:h-8"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="fill: rgba(237, 137, 54, 1); transform: ; -ms-filter: "
                >
                  <path
                    d="M8.267 14.68c-.184 0-.308.018-.372.036v1.178c.076.018.171.023.302.023.479 0 .774-.242.774-.651C8.971 14.9 8.717 14.68 8.267 14.68zM11.754 14.692c-.2 0-.33.018-.407.036v2.61c.077.018.201.018.313.018.817.006 1.349-.444 1.349-1.396C13.015 15.13 12.53 14.692 11.754 14.692z"
                  ></path>
                  <path
                    d="M14,2H6C4.896,2,4,2.896,4,4v16c0,1.104,0.896,2,2,2h12c1.104,0,2-0.896,2-2V8L14,2z M9.498,16.19 c-0.309,0.29-0.765,0.42-1.296,0.42c-0.119,0-0.226-0.006-0.308-0.018v1.426H7v-3.936C7.278,14.036,7.669,14,8.219,14 c0.557,0,0.953,0.106,1.22,0.319c0.254,0.202,0.426,0.533,0.426,0.923C9.864,15.634,9.734,15.965,9.498,16.19z M13.305,17.545 c-0.42,0.349-1.059,0.515-1.84,0.515c-0.468,0-0.799-0.03-1.024-0.06v-3.917C10.772,14.029,11.204,14,11.66,14 c0.757,0,1.249,0.136,1.633,0.426c0.415,0.308,0.675,0.799,0.675,1.504C13.968,16.693,13.689,17.22,13.305,17.545z M17,14.77 h-1.532v0.911h1.432v0.734h-1.432v1.604h-0.906v-3.989H17V14.77z M14,9c-0.553,0-1,0-1,0V4l5,5H14z"
                  ></path>
                </svg>
                <span>Constat</span>
              </button>
              <button
                type="button"
                class="inline-flex -ml-px relative items-center px-2 py-1 md:px-4 md:py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none"
                v-on:click="downloadFacture(constat)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-1 md:mr-2.5 h-6 md:h-8"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="fill: rgba(237, 137, 54, 1); transform: ; -ms-filter: "
                >
                  <path
                    d="M8.267 14.68c-.184 0-.308.018-.372.036v1.178c.076.018.171.023.302.023.479 0 .774-.242.774-.651C8.971 14.9 8.717 14.68 8.267 14.68zM11.754 14.692c-.2 0-.33.018-.407.036v2.61c.077.018.201.018.313.018.817.006 1.349-.444 1.349-1.396C13.015 15.13 12.53 14.692 11.754 14.692z"
                  ></path>
                  <path
                    d="M14,2H6C4.896,2,4,2.896,4,4v16c0,1.104,0.896,2,2,2h12c1.104,0,2-0.896,2-2V8L14,2z M9.498,16.19 c-0.309,0.29-0.765,0.42-1.296,0.42c-0.119,0-0.226-0.006-0.308-0.018v1.426H7v-3.936C7.278,14.036,7.669,14,8.219,14 c0.557,0,0.953,0.106,1.22,0.319c0.254,0.202,0.426,0.533,0.426,0.923C9.864,15.634,9.734,15.965,9.498,16.19z M13.305,17.545 c-0.42,0.349-1.059,0.515-1.84,0.515c-0.468,0-0.799-0.03-1.024-0.06v-3.917C10.772,14.029,11.204,14,11.66,14 c0.757,0,1.249,0.136,1.633,0.426c0.415,0.308,0.675,0.799,0.675,1.504C13.968,16.693,13.689,17.22,13.305,17.545z M17,14.77 h-1.532v0.911h1.432v0.734h-1.432v1.604h-0.906v-3.989H17V14.77z M14,9c-0.553,0-1,0-1,0V4l5,5H14z"
                  ></path>
                </svg>
                <span>Facture</span>
              </button>
            </span>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import NavBar from "../components/navbar";
import FileService from "../services/files.services";

export default {
  name: "Constat",
  components: { NavBar },
  data() {
    return {
      //constatList: [],
      showSortMenu: false,
      sortByDate: true,
      sortByCity: false,
      sortSens: 1,
    };
  },
  methods: {
    refresh() {
      const username = this.$store.getters["auth/getUserName"];
      const driveId = this.$store.getters["auth/getDriveId"];
      this.$store.dispatch("file/fetchConstatFolders", { username, driveId });
    },
    onSortMenuClick() {
      this.showSortMenu = !this.showSortMenu;
    },
    // eslint-disable-next-line no-unused-vars
    onClickOutside(event) {
      if (this.showSortMenu) {
        //console.log("Clicked outside. Event: ", event);
        // this.showSortMenu = false;
      }
    },
    updateSort() {
      if (this.sortSens == 1) {
        this.sortSens = -1;
      } else if (this.sortSens == -1) {
        this.sortSens = 1;
      }
      //this.getConstatFolder().reverse();
    },
    setSortByVille() {
      this.sortByCity = true;
      this.sortByDate = false;
      this.showSortMenu = false;
      this.constatList.sort(function (a, b) {
        //console.log("sort by adresse");
        return a.ville.localeCompare(b.ville);
      });
    },
    setSortByDate() {
      this.sortByCity = false;
      this.sortByDate = true;
      this.showSortMenu = false;
      this.constatList.sort(function (a, b) {
        //console.log("sort by date");
        return new Date(b.date) - new Date(a.date);
      });
    },
    downloadVideo(elt) {
      const username = this.$store.getters["auth/getUserName"];
      FileService.getFolderFiles(username, elt.driveId, elt.itemId).then(
        (response) => {
          // console.log(response);
          response.value.forEach((element) => {
            //console.log(element.name);
            if (element.name.endsWith("mp4")) {
              this.forceFileDownload(element["@microsoft.graph.downloadUrl"]);
            }
          });
        }
      );
    },
    downloadConstat(elt) {
      const username = this.$store.getters["auth/getUserName"];
      FileService.getFolderFiles(username, elt.driveId, elt.itemId).then(
        (response) => {
          //console.log(response);
          response.value.forEach((element) => {
            //console.log(element.name);
            if (
              !element.name.endsWith("FACTURE.pdf") &&
              element.name.endsWith(".pdf")
            ) {
              this.forceFileDownload(
                element["@microsoft.graph.downloadUrl"],
                element.name
              );
            }
          });
        }
      );
    },
    downloadFacture(elt) {
      const username = this.$store.getters["auth/getUserName"];
      FileService.getFolderFiles(username, elt.driveId, elt.itemId).then(
        (response) => {
          // console.log(response);
          response.value.forEach((element) => {
            //console.log(element.name);
            if (element.name.endsWith("FACTURE.pdf")) {
              this.forceFileDownload(
                element["@microsoft.graph.downloadUrl"],
                element.name
              );
            }
          });
        }
      );
    },
    fetchFolder(driveId, itemId) {
      const username = this.$store.getters["auth/getUserName"];
      FileService.getFolderFiles(username, driveId, itemId).then((response) => {
        return response;
      });
    },
    forceFileDownload(url, name) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    },
  },
  created() {
    const username = this.$store.getters["auth/getUserName"];
    const driveId = this.$store.getters["auth/getDriveId"];
    this.$store.dispatch("file/fetchConstatFolders", { username, driveId });
  },
  computed: {
    getConstatFolder() {
      const sortByCity = this.sortByCity;
      return this.$store.getters["file/getConstatFolders"]
        .map((elt) => {
          //       if (elt.name.startsWith("CST")) {
          const parsedname = elt.name.split("_");
          // CST_20210603_VILLEURBANNE_Rue Benjamin Constant_C49429
          return {
            ref: parsedname[parsedname.length - 1],
            date: parsedname[1],
            ville: parsedname[2],
            adresse: parsedname[3],
            driveId: elt.driveId,
            itemId: elt.itemId,
          };
          //       }
        })
        .sort(function (a, b) {
          if (sortByCity) {
            //console.log("sort by adresse");
            return a.ville.localeCompare(b.ville);
          } else {
            //console.log("sort by date");
            return new Date(b.date) - new Date(a.date);
          }
        });
      /*console.log("get constat folders");
      const parsedname = "20210603 - Villeurbanne - Rue Benjamin Constant - C49429".split(
        " - "
      );
      console.log(parsedname);
      return [
        {
          ref: parsedname[3],
          date: parsedname[0],
          ville: parsedname[1],
          adresse: parsedname[2],
        },
        /*,
        {
          ref: "C49428",
          date: parsedname[0],
          ville: parsedname[1],
          adresse: parsedname[2],
        },
        {
          ref: "C49427",
          date: parsedname[0],
          ville: parsedname[1],
          adresse: parsedname[2],
        },
        {
          ref: "C49425",
          date: parsedname[0],
          ville: parsedname[1],
          adresse: parsedname[2],
        },*/
      // ];
    },
  },
};
</script>

<style>
.text-orange {
  color: #ed8936;
}

.constat-page input[name="panel"]:checked ~ div label .arrow-up {
  display: block;
}

.constat-page input[name="panel"]:checked ~ div label .arrow-down {
  display: none;
}

.constat-page .arrow-up {
  display: none;
}

.constat-page .arrow-down {
  display: block;
}

/*.constat-page label.svg-label .arrow-up {
  display: none;
}

.constat-page label.svg-label .arrow-down {
  display: block;
}

.constat-page input:checked + div label.svg-label .arrow-down {
  display: none;
}

.constat-page input:checked + div label.svg-label .arrow-up {
  display: block;
}*/

.constat-page .accordion__content {
  max-height: 0em;
  transition: all 0.4s cubic-bezier(0.865, 0.14, 0.095, 0.87);
}

.constat-page input[name="panel"]:checked ~ .accordion__content {
  max-height: 50em;
}
</style>
