<template>
  <div class="min-w-screen min-h-screen bg-gray-50">
    <NavBar></NavBar>
    <main class="container mx-auto flex-1 relative pb-8 z-0 mt-8">
      <div class="mx-1 md:px-0 bg-white py-5 border-b border-gray-200">
        <div
          class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
        >
          <div class="ml-4 mt-2">
            <h2
              class="text-xl md:text-2xl font-bold leading-7 text-gray-500 sm:text-3xl sm:truncate"
            >
              Vos fichiers
            </h2>
          </div>
          <div class="flex ml-4 text-xs md:text-base">
            <FilterSorter filterTarget="file"></FilterSorter>
            <button
              type="button"
              class="ml-2 inline-flex items-center border border-transparent bg-orange-500 hover:bg-orange-700 mt-2 px-1 py-1 md:py-2 text-white rounded text-center font-bold"
              v-if="hasCommonFolder"
              v-on:click="refresh()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flex-shrink-0 md:mr-1.5 h-5 w-5 text-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  d="M10 11H7.101l.001-.009a4.956 4.956 0 0 1 .752-1.787 5.054 5.054 0 0 1 2.2-1.811c.302-.128.617-.226.938-.291a5.078 5.078 0 0 1 2.018 0 4.978 4.978 0 0 1 2.525 1.361l1.416-1.412a7.036 7.036 0 0 0-2.224-1.501 6.921 6.921 0 0 0-1.315-.408 7.079 7.079 0 0 0-2.819 0 6.94 6.94 0 0 0-1.316.409 7.04 7.04 0 0 0-3.08 2.534 6.978 6.978 0 0 0-1.054 2.505c-.028.135-.043.273-.063.41H2l4 4 4-4zm4 2h2.899l-.001.008a4.976 4.976 0 0 1-2.103 3.138 4.943 4.943 0 0 1-1.787.752 5.073 5.073 0 0 1-2.017 0 4.956 4.956 0 0 1-1.787-.752 5.072 5.072 0 0 1-.74-.61L7.05 16.95a7.032 7.032 0 0 0 2.225 1.5c.424.18.867.317 1.315.408a7.07 7.07 0 0 0 2.818 0 7.031 7.031 0 0 0 4.395-2.945 6.974 6.974 0 0 0 1.053-2.503c.027-.135.043-.273.063-.41H22l-4-4-4 4z"
                ></path>
              </svg>
              Rafraîchir
            </button>
            <input
              type="file"
              multiple
              id="file"
              ref="file"
              v-on:change="handleFileUpload()"
              class="hidden"
            />
            <!--button
              v-if="hasCommonFolder"
              type="button"
              v-on:click="submitFile()"
              class="relative inline-flex items-center mt-2 bg-orange-500 hover:bg-orange-700 px-1 md:px-3 py-1 md:py-2 text-white rounded text-center font-bold w-full"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flex-shrink-0 md:mr-1.5 h-5 w-5 text-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  d="M18.944 11.112C18.507 7.67 15.56 5 12 5 9.244 5 6.85 6.611 5.757 9.15 3.609 9.792 2 11.82 2 14c0 2.757 2.243 5 5 5h11c2.206 0 4-1.794 4-4a4.01 4.01 0 0 0-3.056-3.888zM13 14v3h-2v-3H8l4-5 4 5h-3z"
                ></path>
              </svg>
              Envoyer
            </button>-->
          </div>
        </div>
      </div>
      <div
        class="mx-1 md:px-0"
        @drop.prevent="onDrop"
        @dragover.prevent
        v-if="hasCommonFolder"
      >
        <label
          class="flex justify-center w-full h-20 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
        >
          <span class="flex items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              />
            </svg>
            <span class="font-medium text-gray-600">
              Deposez ou cliquez pour envoyer vos fichiers
            </span>
          </span>
          <input
            type="file"
            multiple
            id="file"
            ref="file"
            v-on:change="handleFileUpload()"
            class="hidden"
          />
        </label>
      </div>
      <ul
        class="divide-y divide-gray-200 w-full border-b bg-white border-gray-200"
      >
        <li v-for="file in getFiles" :key="file.id">
          <div class="px-4 py-2 md:py-4 flex items-center sm:px-6">
            <div
              class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between"
            >
              <div class="truncate">
                <div class="flex text-sm">
                  <svg
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      d="M18 22a2 2 0 0 0 2-2V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12zM13 4l5 5h-5V4zM7 8h3v2H7V8zm0 4h10v2H7v-2zm0 4h10v2H7v-2z"
                    ></path>
                  </svg>
                  <p class="font-medium text-gray-600 truncate">
                    {{ file.name }}
                  </p>
                </div>
                <div class="mt-2 flex">
                  <div class="flex items-center text-sm text-gray-500">
                    <svg
                      class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path
                        d="M21 20V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2zM9 18H7v-2h2v2zm0-4H7v-2h2v2zm4 4h-2v-2h2v2zm0-4h-2v-2h2v2zm4 4h-2v-2h2v2zm0-4h-2v-2h2v2zm2-5H5V7h14v2z"
                      ></path>
                    </svg>
                    <p>
                      Envoyer le
                      <time datetime="formatDate(file.createdDateTime)">{{
                        formatDate(file.createdDateTime)
                      }}</time>
                    </p>
                  </div>
                </div>
              </div>
              <!--<div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5"></div>-->
            </div>
            <button class="ml-5 flex-shrink-0" v-on:click="downloadFile(file)">
              <svg
                class="flex-shrink-0 mr-1.5 h-5 w-5 text-orange-500"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="m12 16 4-5h-3V4h-2v7H8z"></path>
                <path
                  d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"
                ></path>
              </svg>
            </button>
          </div>
        </li>
      </ul>
    </main>
  </div>
</template>

<script>
import NavBar from "../components/navbar";
import FilterSorter from "../components/filter.vue";
import FileService from "../services/files.services";

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
export default {
  name: "Constat",
  components: { NavBar, FilterSorter },
  data() {
    return {
      showSortMenu: false,
    };
  },
  methods: {
    refresh() {
      this.$store.getters["file/getFileOrderBy"];
      const username = this.$store.getters["auth/getUserName"];
      const driveId = this.$store.getters["auth/getDriveId"];
      this.$store.dispatch("file/fetchUserCommonFiles", { username, driveId });
    },
    formatDate(date) {
      const jsDate = new Date(date);
      return jsDate.toLocaleDateString(undefined, options);
    },
    downloadFile(file) {
      const link = document.createElement("a");
      link.href = file.downloadUrl;
      link.setAttribute("download", file.name);
      document.body.appendChild(link);
      link.click();
    },
    onDrop(e) {
      this.upload([...e.dataTransfer.files]);
    },
    handleFileUpload() {
      this.upload([...this.$refs.file.files]);
    },
    upload(files) {
      const driveId = this.$store.getters["file/getCommonFolderDriveId"];
      const parentItemId = this.$store.getters["file/getCommonFolderItemId"];

      const username = this.$store.getters["auth/getUserName"];
      files.forEach((file) => {
        const name = file.name;
        const size = file.size;
        const reader = new FileReader();

        // console.log(file);
        // console.log(name);
        // console.log(size);

        let self = this;
        reader.onload = function () {
          FileService.uploadFiles(
            username,
            driveId,
            parentItemId,
            name,
            size,
            this.result
          ).then((response) => {
            self.refresh();
            return response;
          });
        };
        reader.readAsArrayBuffer(file);
      });
    },
    submitFile() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
  },
  created() {
    this.refresh();
  },
  computed: {
    getFiles() {
      return this.$store.getters["file/getUserCommonFiles"];
    },
    hasCommonFolder() {
      return this.$store.getters["file/hasCommonFolder"];
    },
  },
};
</script>

<style></style>
