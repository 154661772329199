import GraphService from "../graph/graph.service";
import AuthService from "../services/auth.service";
import { endpoint } from "../graph/graphConfig";

// Add scopes here for access token to be used at Microsoft Graph API endpoints.
const tokenRequest = {
  scopes: ["User.Read", "Files.Read.All", "Sites.Read.All"],
};

class FileService {
  async callGraph(username, graphUrl) {
    return await AuthService.getTokenPopup(tokenRequest, username)
      .then((response) => {
        return GraphService.callMSGraph(graphUrl, response.accessToken);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getUserSite(username) {
    return await AuthService.getTokenPopup(tokenRequest, username)
      .then((response) => {
        return GraphService.callMSGraph(
          endpoint.userRootSite,
          response.accessToken
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getUserFirstSubSite(username, siteId) {
    return await AuthService.getTokenPopup(tokenRequest, username)
      .then((response) => {
        return GraphService.callMSGraph(
          endpoint.userSiteBase + siteId + "/sites",
          response.accessToken
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getUserDriveId(username, siteId) {
    return await AuthService.getTokenPopup(tokenRequest, username)
      .then((response) => {
        return GraphService.callMSGraph(
          endpoint.userSiteBase + siteId + "/drive",
          response.accessToken
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getUserSharedFolder(username) {
    //console.log("auth service getUserFiles");
    return await AuthService.getTokenPopup(tokenRequest, username)
      .then((response) => {
        return GraphService.callMSGraphGetSharedFolders(response.accessToken);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getUserRootFolder(username) {
    //console.log("auth service getUserFiles");
    return await AuthService.getTokenPopup(tokenRequest, username)
      .then((response) => {
        const drive = GraphService.callMSGraphGetUserDrive(
          response.accessToken
        );
        return drive;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getDriveChildren(username, driveId) {
    return await AuthService.getTokenPopup(tokenRequest, username)
      .then((response) => {
        const drive = GraphService.callMSGraphGetDriveChildren(
          response.accessToken,
          driveId
        );
        return drive;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async getFolderFiles(username, driveId, itemId) {
    //console.log("auth service getUserFiles");
    return await AuthService.getTokenPopup(tokenRequest, username)
      .then((response) => {
        return GraphService.callMSGraphGetConstatFiles(
          response.accessToken,
          driveId,
          itemId
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async uploadFiles(username, driveId, parentItemId, name, size, content) {
    //console.log("auth service getUserFiles");
    return await AuthService.getTokenPopup(tokenRequest, username)
      .then((response) => {
        return GraphService.callMSGraphUploadFiles(
          response.accessToken,
          driveId,
          parentItemId,
          name,
          size,
          content
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }
}

export default new FileService();
