<template>
  <span class="relative z-0 inline-flex shadow-sm rounded-md mt-2">
    <button
      type="button"
      @click.prevent="showSortMenu = !showSortMenu"
      class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-600 hover:bg-gray-50 focus:z-10"
      id="sort-menu"
      aria-expanded="false"
      aria-haspopup="true"
    >
      Trie
      <svg
        class="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <span class="-ml-px relative block">
      <button
        type="button"
        v-on:click="setOrderDirection()"
        class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 h-full"
        id="option-menu-button"
        aria-expanded="true"
        aria-haspopup="true"
      >
        <svg
          v-show="orderDirection == 'asc'"
          class="mr-3 h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z"
          ></path>
        </svg>
        <svg
          v-show="orderDirection == 'desc'"
          xmlns="http://www.w3.org/2000/svg"
          class="mr-3 h-5 w-5 text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z"
          />
        </svg>
      </button>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <div
          v-show="showSortMenu"
          class="origin-top-right absolute -ml-12 mt-1 w-52 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="option-menu-button"
          tabindex="-1"
        >
          <div class="py-1" role="none">
            <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
            <label
              v-bind:class="{
                'bg-gray-100 text-gray-900': orderBy == 'date',
              }"
              class="group flex items-center px-4 py-2 text-sm w-full"
            >
              <input
                v-model="orderBy"
                type="radio"
                value="date"
                class="hidden"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                v-bind:class="{
                  'text-orange-500': orderBy == 'date',
                  'text-gray-400': orderBy != 'date',
                }"
                class="mr-3 h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"
                />
              </svg>
              Date de mise en ligne
              <span
                class="absolute right-0 flex items-center pr-4 text-orange-500"
              >
                <svg
                  class="h-5 w-5 hidden"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
            </label>
            <label
              v-bind:class="{
                'bg-gray-100 text-gray-900': orderBy == 'name',
              }"
              class="group flex items-center px-4 py-2 text-sm w-full"
            >
              <input
                v-model="orderBy"
                type="radio"
                value="name"
                class="hidden"
                role="menuitem"
                tabindex="-1"
                id="menu-item-1"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                v-bind:class="{
                  'text-orange-500': orderBy == 'name',
                  'text-gray-400': orderBy != 'name',
                }"
                class="mr-3 h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                  clip-rule="evenodd"
                />
              </svg>
              Nom
              <span
                class="absolute right-0 flex items-center pr-4 text-orange-500"
              >
                <svg
                  class="h-5 w-5 hidden"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
            </label>
          </div>
        </div>
      </transition>
    </span>
  </span>
</template>

<script>
export default {
  name: "Filter",
  data() {
    return {
      showSortMenu: false,
    };
  },
  props: {
    filterTarget: String,
  },
  methods: {
    setOrderDirection() {
      if (this.filterTarget == "file") {
        this.$store.dispatch("file/updateFileOrderDirection");
      }
    },
  },
  computed: {
    orderBy: {
      set(orderBy) {
        if (this.filterTarget == "file") {
          this.$store.dispatch("file/updateFileOrderBy", orderBy);
        }
        this.showSortMenu = false;
      },
      get() {
        if (this.filterTarget == "file") {
          return this.$store.getters["file/getFileOrderBy"];
        }
        return "name";
      },
    },
    orderDirection() {
      if (this.filterTarget == "file") {
        return this.$store.getters["file/getFileOrderDirection"];
      }
      return "asc";
    },
  },
  created() {
    window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.showSortMenu = false;
      }
    });
  },
};
</script>

<style></style>
