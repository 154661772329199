import { endpoint } from "./graphConfig";
import axios from "axios";

class GraphService {
  callMSGraph(endpoint, token) {
    //const headers = new Headers();
    //const bearer = `Bearer ${token}`;
    //headers.append("Authorization", bearer);
    return axios
      .get(endpoint, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  callMSGraphGetSharedFolders(token) {
    //return this.callMSGraph(endpoint.graphDriveEndpoint, token);
    return this.callMSGraph(endpoint.graphSharedFolderEndpoint, token);
  }

  callMSGraphGetUserDrive(token) {
    //return this.callMSGraph(endpoint.graphDriveEndpoint, token);
    return this.callMSGraph(endpoint.graphUserDriveEndpoint, token);
  }

  callMSGraphGetDriveChildren(token, driveId) {
    //return this.callMSGraph(endpoint.graphDriveEndpoint, token);
    return this.callMSGraph(
      endpoint.graphUserDriveEndpoint + "/" + driveId + "/root/children",
      token
    );
  }

  callMSGraphUploadFiles(token, driveId, itemId, name, size, content) {
    //POST /drives/{driveId}/items/{itemId}/createUploadSession
    // const array = new Uint8Array(content);
    // array = new Uint8Array(arrayBuffer);
    // binaryString = String.fromCharCode.apply(null, array);
    //const binaryString = String.fromCharCode.apply(null, array);
    const bytes = content.byteLength;
    const range = `bytes ${0}-${0 + bytes - 1}/${bytes}`;

    const uploadHeaders = {
      "Content-Range": range,
      "Content-Type": "application/octet-stream",
      "Content-Length": bytes,
    };

    //console.log(uploadHeaders);
    const endpointCall =
      endpoint.graphUserDriveEndpoint +
      driveId +
      "/items/" +
      itemId +
      ":/" +
      name +
      ":/createUploadSession";
    //console.log(endpointCall);

    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };

    const bodyParameters = {
      item: {
        "@microsoft.graph.conflictBehavior": "rename",
        //fileSize: size,
        name: name,
      },
      //deferCommit: true,
    };

    return axios
      .post(endpointCall, bodyParameters, config)
      .then((response) => {
        //console.log(response.data.uploadUrl);
        return axios
          .put(response.data.uploadUrl, content, {
            headers: uploadHeaders,
          })
          .then((response) => {
            return response.data;
          });
        //console.log(content);
      })
      .catch(console.log);

    /* return axios
      .put(endpointCall, content, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "text/plain",
        },
      })
      .then((response) => {
        return response.data;
      });*/
    //console.log(content);
    //return this.callMSGraph(endpointCall, token);
  }

  callMSGraphGetConstatFiles(token, driveId, itemId) {
    //return this.callMSGraph(endpoint.graphDriveEndpoint, token);
    const endpointCall =
      endpoint.graphUserDriveEndpoint +
      driveId +
      "/items/" +
      itemId +
      "/children";
    //console.log(endpointCall);
    return this.callMSGraph(endpointCall, token);
  }
}

export default new GraphService();
