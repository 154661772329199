<template>
  <div class="border-t-4 border-orange-500 bg-white shadow-sm">
    <div class="container mx-auto flex px-2 lg:px-0">
      <router-link to="/Home" class="flex-shrink-0 flex items-center py-4">
        <img
          class="block h-16 w-auto"
          src="@/assets/logo.png"
          alt="Logo huissier reunis"
        />
      </router-link>
      <div class="flex-1 px-4 flex justify-between"></div>
      <div v-if="isLogged" class="ml-3 relative my-auto">
        <div>
          <button
            @click="showDropdown = !showDropdown"
            type="button"
            class="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none p-2 lg:hover:bg-gray-50 my-auto"
            id="user-menu"
            aria-expanded="false"
            aria-haspopup="true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              style="fill: rgba(156, 163, 175, 1)"
            >
              <path
                fill="none"
                d="M12,8c-1.178,0-2,0.822-2,2s0.822,2,2,2s2-0.822,2-2S13.178,8,12,8z"
              ></path>
              <path
                fill="none"
                d="M12,4c-4.337,0-8,3.663-8,8c0,2.176,0.923,4.182,2.39,5.641c0.757-1.8,2.538-3.068,4.61-3.068h2 c2.072,0,3.854,1.269,4.61,3.068C19.077,16.182,20,14.176,20,12C20,7.663,16.337,4,12,4z M12,14c-2.28,0-4-1.72-4-4s1.72-4,4-4 s4,1.72,4,4S14.28,14,12,14z"
              ></path>
              <path
                fill="none"
                d="M13,16.572h-2c-1.432,0-2.629,1.01-2.926,2.354C9.242,19.604,10.584,20,12,20s2.758-0.396,3.926-1.073 C15.629,17.582,14.432,16.572,13,16.572z"
              ></path>
              <path
                d="M12,2C6.579,2,2,6.579,2,12c0,3.189,1.592,6.078,4,7.924V20h0.102C7.77,21.245,9.813,22,12,22s4.23-0.755,5.898-2H18 v-0.076c2.408-1.846,4-4.734,4-7.924C22,6.579,17.421,2,12,2z M8.074,18.927c0.297-1.345,1.494-2.354,2.926-2.354h2 c1.432,0,2.629,1.01,2.926,2.354C14.758,19.604,13.416,20,12,20S9.242,19.604,8.074,18.927z M17.61,17.641 c-0.757-1.8-2.538-3.068-4.61-3.068h-2c-2.072,0-3.854,1.269-4.61,3.068C4.923,16.182,4,14.176,4,12c0-4.337,3.663-8,8-8 s8,3.663,8,8C20,14.176,19.077,16.182,17.61,17.641z"
              ></path>
              <path
                d="M12,6c-2.28,0-4,1.72-4,4s1.72,4,4,4s4-1.72,4-4S14.28,6,12,6z M12,12c-1.178,0-2-0.822-2-2s0.822-2,2-2s2,0.822,2,2 S13.178,12,12,12z"
              ></path>
            </svg>
            <span
              class="hidden ml-3 text-gray-700 text-sm font-medium lg:block"
            >
              {{ accountName }}
            </span>
            <!-- Heroicon name: solid/chevron-down -->
            <svg
              class="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <div
            v-show="showDropdown"
            class="origin-top-right z-50 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            <button
              v-on:click="logout"
              class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 z-50"
              role="menuitem"
            >
              Se deconnecter
            </button>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    isLogged() {
      return true;
      //return this.$store.getters["auth/isLogged"];
    },
    accountName() {
      return this.$store.getters["auth/getAccountName"];
    },
  },
  methods: {
    async logout() {
      this.$store.dispatch("auth/logout");
    },
  },
  created() {
    window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.showDropdown = false;
      }
    });
  },
};
</script>

<style></style>
