<template>
  <body class>
    <div class="lg:flex">
      <div class="lg:w-1/2 xl:max-w-screen-sm">
        <div
          class="py-4 bg-orange-300 lg:bg-white justify-center lg:justify-start lg:px-12"
        >
          <div class="cursor-pointer flex items-center">
            <img
              class="px-12 block h-32 w-auto"
              src="@/assets/logo.png"
              alt="Logo huissier reunis"
            />
          </div>
          <blockquote class="mt-2 pl-12 flex items-cente">
            <p class="italic text-md text-bold text-gray-600">
              « La culture de l’action, pour un recouvrement tout terrain »
            </p>
          </blockquote>
        </div>
        <div
          class="mt-4 px-4 sm:px-24 md:px-48 lg:px-12 lg:mt-16 xl:px-24 xl:max-w-2xl"
        >
          <h2
            class="text-center lg:text-2xl text-bold text-gray-600 font-display font-semibold lg:text-left"
          >
            Connectez-vous à votre espace sécurisé
          </h2>
          <div class="mt-4 lg:mt-12">
            <form @submit.prevent="login" action="#" method="POST">
              <div>
                <!--<div class="text-sm font-bold text-gray-700 tracking-wide">
                  Adresse e-mail
                </div>-->
                <input
                  class="w-full text-lg py-2 border-b border-gray-300 focus:outline-none"
                  type=""
                  placeholder="votre adresse e-mail"
                />
              </div>
              <div class="mt-10">
                <button
                  type="submit"
                  class="mt-2 block bg-orange-500 hover:bg-orange-700 px-3 py-2 text-white rounded text-center font-bold w-full"
                  title="Espace Client"
                >
                  Se connecter
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="fixed bottom-1 lg:bottom-10">
          <a href="http://www.inter-huissiers.fr/" class="lg:flex">
            <img
              class="ml-2 lg:ml-4 block h-8 lg:h-10 w-auto self-end"
              src="@/assets/inter_huissiers.png"
              alt="Logo huissier reunis"
            />
            <p class="ml-2 lg:ml-4 self-end text-xs lg:text-base">
              Pilote et réfèrent du réseau national d’Huissier de Justice
            </p>
          </a>
        </div>
      </div>
      <div
        class="hidden lg:flex items-center justify-center bg-orange-300 flex-1 h-screen"
      >
        <div
          class="max-w-xs transform duration-200 hover:scale-110 cursor-pointer"
        >
          <svg
            id="a5c16198-98a1-478b-8909-43624583dcf2"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            width="793"
            height="551.73152"
            viewBox="0 0 793 551.73152"
            class="mx-auto h-80 w-auto"
          >
            <path
              d="M977.70889,651.09727H417.29111A18.79111,18.79111,0,0,1,398.5,632.30616h0q304.727-35.41512,598,0h0A18.79111,18.79111,0,0,1,977.70889,651.09727Z"
              transform="translate(-203.5 -174.13424)"
              fill="#2f2e41"
            ></path>
            <path
              d="M996.5,633.41151l-598-1.10536,69.30611-116.61553.3316-.55268V258.13057a23.7522,23.7522,0,0,1,23.75418-23.75418H899.792a23.7522,23.7522,0,0,1,23.75418,23.75418V516.90649Z"
              transform="translate(-203.5 -174.13424)"
              fill="#3f3d56"
            ></path>
            <path
              d="M491.35028,250.95679a7.74623,7.74623,0,0,0-7.73753,7.73753V493.03073a7.74657,7.74657,0,0,0,7.73753,7.73752H903.64972a7.74691,7.74691,0,0,0,7.73753-7.73752V258.69432a7.74657,7.74657,0,0,0-7.73753-7.73753Z"
              transform="translate(-203.5 -174.13424)"
              fill="#fff"
            ></path>
            <path
              d="M493.07794,531.71835a3.32522,3.32522,0,0,0-3.01275,1.93006l-21.35537,46.42514a3.31594,3.31594,0,0,0,3.01221,4.7021H920.81411a3.3157,3.3157,0,0,0,2.96526-4.79925L900.5668,533.55126a3.29926,3.29926,0,0,0-2.96526-1.83291Z"
              transform="translate(-203.5 -174.13424)"
              fill="#2f2e41"
            ></path>
            <circle
              cx="492.34196"
              cy="67.97967"
              r="4.97412"
              fill="#fff"
            ></circle>
            <path
              d="M651.69986,593.61853a3.32114,3.32114,0,0,0-3.20165,2.4536l-5.35679,19.89649a3.31576,3.31576,0,0,0,3.20166,4.17856h101.874a3.31531,3.31531,0,0,0,3.13257-4.40093l-6.88691-19.89649a3.31784,3.31784,0,0,0-3.13366-2.23123Z"
              transform="translate(-203.5 -174.13424)"
              fill="#2f2e41"
            ></path>
            <polygon
              points="720.046 337.135 720.046 341.556 264.306 341.556 264.649 341.004 264.649 337.135 720.046 337.135"
              fill="#2f2e41"
            ></polygon>
            <circle
              cx="707.33457"
              cy="77.37523"
              r="77.37523"
              fill="#f9a826"
            ></circle>
            <path
              d="M942.89,285.223H878.77911a4.42582,4.42582,0,0,1-4.42144-4.42145V242.11391a4.42616,4.42616,0,0,1,4.42144-4.42144H942.89a4.42616,4.42616,0,0,1,4.42144,4.42144v38.68761A4.42582,4.42582,0,0,1,942.89,285.223Zm-64.11091-43.10906v38.68761h64.11415L942.89,242.11391Z"
              transform="translate(-203.5 -174.13424)"
              fill="#fff"
            ></path>
            <path
              d="M930.73105,242.11391h-39.793V224.42814c0-12.80987,8.36792-22.10721,19.89649-22.10721s19.89648,9.29734,19.89648,22.10721Zm-35.37153-4.42144h30.95009V224.42814c0-10.413-6.36338-17.68576-15.475-17.68576s-15.47505,7.27281-15.47505,17.68576Z"
              transform="translate(-203.5 -174.13424)"
              fill="#fff"
            ></path>
            <circle
              cx="707.33457"
              cy="86.21811"
              r="4.42144"
              fill="#fff"
            ></circle>
            <path
              d="M856.81994,421.28372H538.18006a5.90767,5.90767,0,0,1-5.90073-5.90073V336.342a5.90767,5.90767,0,0,1,5.90073-5.90072H856.81994a5.90767,5.90767,0,0,1,5.90073,5.90072V415.383A5.90767,5.90767,0,0,1,856.81994,421.28372Zm-318.63988-88.4821a3.5443,3.5443,0,0,0-3.54043,3.54043V415.383a3.54431,3.54431,0,0,0,3.54043,3.54044H856.81994a3.54431,3.54431,0,0,0,3.54043-3.54044V336.342a3.5443,3.5443,0,0,0-3.54043-3.54043Z"
              transform="translate(-203.5 -174.13424)"
              fill="#e6e6e6"
            ></path>
            <circle
              cx="384.19021"
              cy="198.69546"
              r="24.03645"
              fill="#e6e6e6"
            ></circle>
            <path
              d="M643.203,356.80541a4.00608,4.00608,0,1,0,0,8.01215H832.06074a4.00607,4.00607,0,0,0,0-8.01215Z"
              transform="translate(-203.5 -174.13424)"
              fill="#e6e6e6"
            ></path>
            <path
              d="M643.203,380.84186a4.00607,4.00607,0,1,0,0,8.01214H724.469a4.00607,4.00607,0,1,0,0-8.01214Z"
              transform="translate(-203.5 -174.13424)"
              fill="#e6e6e6"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
    };
  },
  computed: {
    isLogged() {
      //return true;
      return this.$store.getters["auth/isLogged"];
    },
  },
  methods: {
    async login() {
      this.$store.dispatch("auth/login", this.username);
    },
  },
};
</script>

<style>
.bg-orange-300 {
  background-color: #fbd38d;
}
</style>
