<template>
  <div class="min-w-screen min-h-screen bg-gray-50">
    <NavBar></NavBar>
    <main
      class="container mx-auto flex-1 relative pb-2 md:pb-8 z-0 overflow-y-auto mt-4 md:mt-8"
    >
      <!-- Page header -->
      <div class="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
        <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div class="md:py-6 md:flex md:items-center md:justify-between">
            <div class="flex-1 min-w-0">
              <!-- Profile -->
              <div class="flex items-center">
                <div>
                  <div class="flex items-center">
                    <span
                      class="inline-flex items-center justify-center h-14 w-14 rounded-full bg-orange"
                    >
                      <span
                        class="text-xl font-medium leading-none text-white"
                        >{{ initial }}</span
                      >
                    </span>
                    <div class="pt-1.5">
                      <h1
                        class="ml-3 md:text-2xl font-bold leading-7 text-gray-600 sm:leading-9 sm:truncate"
                      >
                        Bienvenue, {{ accountName }}
                      </h1>
                      <p
                        class="hidden md:block ml-3 text-xl font-medium text-gray-500"
                      >
                        Consultez vos documents ou constat, déposez vos fichiers
                      </p>
                    </div>
                  </div>
                  <p class="md:hidden pt-1.5 text-sm font-medium text-gray-500">
                    Consultez vos documents ou constat, déposez vos fichiers
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="my-4 md:my-16 md:flex">
        <router-link
          to="/Constat"
          class="bg-white md:mr-8 shadow sm:rounded-lg my-8 md:my-0 md:w-1/2 h-72 lg:h-96 text-center mt-4 flex flex-col justify-between align-content-start p-4 md:p-8"
        >
          <svg
            id="ad1dd13e-2ec4-4c24-88e7-d38d592282c0"
            class="lg:w-2/3 self-start mx-auto py-2 md:py-4 w-1/2 md:w-1/3 lg:w-auto"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 578 675"
          >
            <path
              d="M694,112.5H324a13.01442,13.01442,0,0,0-13,13v500a13.01474,13.01474,0,0,0,13,13H694a13.01505,13.01505,0,0,0,13-13v-500A13.01474,13.01474,0,0,0,694,112.5Z"
              transform="translate(-311 -112.5)"
              fill="#f2f2f2"
            />
            <path
              d="M335.42966,128.5A9.08459,9.08459,0,0,0,326.5,137.71212V613.28788A9.08459,9.08459,0,0,0,335.42966,622.5H682.57034A9.08459,9.08459,0,0,0,691.5,613.28788V137.71212A9.08459,9.08459,0,0,0,682.57034,128.5Z"
              transform="translate(-311 -112.5)"
              fill="#fff"
            />
            <path
              d="M353.5,150.5c-3.30859,0-6,3.03744-6,6.77143v65.45714c0,3.734,2.69141,6.77143,6,6.77143h311c3.30859,0,6-3.03744,6-6.77143V157.27143c0-3.734-2.69141-6.77143-6-6.77143Z"
              transform="translate(-311 -112.5)"
              fill="#e6e6e6"
            />
            <path
              d="M353.5,261.25a6.09464,6.09464,0,0,0-6,6.1723V407.3277a6.09464,6.09464,0,0,0,6,6.1723h311a6.09464,6.09464,0,0,0,6-6.1723V267.4223a6.09464,6.09464,0,0,0-6-6.1723Z"
              transform="translate(-311 -112.5)"
              fill="#e6e6e6"
            />
            <rect x="75" y="47.25" width="246" height="14" fill="#fff" />
            <rect x="75.5" y="76.75" width="246" height="6.5" fill="#fff" />
            <rect x="75.5" y="88.25" width="246" height="6.5" fill="#fff" />
            <rect x="75.5" y="99.75" width="246" height="6.5" fill="#fff" />
            <path
              d="M532.1626,413.75H645.37354a3.6445,3.6445,0,0,0,2.57714-6.22119l-72.45654-72.45654a3.64844,3.64844,0,0,0-5.15381,0l-18.01806,18.01806a7.64285,7.64285,0,0,1-10.81006,0l-56.43506-56.43506a3.64905,3.64905,0,0,0-5.1543,0L369.54932,407.02881a3.6445,3.6445,0,0,0,2.57714,6.22119H532.30762Z"
              transform="translate(-311 -112.5)"
              fill="#fff"
            />
            <circle cx="86.5" cy="181.75" r="14" fill="#fff" />
            <path
              d="M353.5,446.25a6.09464,6.09464,0,0,0-6,6.1723V592.3277a6.09464,6.09464,0,0,0,6,6.1723h311a6.09464,6.09464,0,0,0,6-6.1723V452.4223a6.09464,6.09464,0,0,0-6-6.1723Z"
              transform="translate(-311 -112.5)"
              fill="#e6e6e6"
            />
            <path
              d="M532.1626,598.75H645.37354a3.64489,3.64489,0,0,0,2.57714-6.22168l-72.45654-72.456a3.6502,3.6502,0,0,0-5.15381,0l-18.01806,18.01757a7.64107,7.64107,0,0,1-10.81055,0l-56.43457-56.43457a3.65082,3.65082,0,0,0-5.1543,0L369.54932,592.02832a3.64489,3.64489,0,0,0,2.57714,6.22168H532.30762Z"
              transform="translate(-311 -112.5)"
              fill="#fff"
            />
            <circle cx="86.5" cy="366.75" r="14" fill="#fff" />
            <path
              d="M454,227.5a9.01047,9.01047,0,0,0-9,9v500a9.01016,9.01016,0,0,0,9,9H824a9.00984,9.00984,0,0,0,9-9v-500a9.01016,9.01016,0,0,0-9-9Z"
              transform="translate(-311 -112.5)"
              fill="#e6e6e6"
            />
            <path
              d="M464.92966,241.5A9.08459,9.08459,0,0,0,456,250.71212V726.28788A9.08459,9.08459,0,0,0,464.92966,735.5H812.07034A9.08459,9.08459,0,0,0,821,726.28788V250.71212A9.08459,9.08459,0,0,0,812.07034,241.5Z"
              transform="translate(-311 -112.5)"
              fill="#fff"
            />
            <path
              d="M519.25,310.68262a4.00458,4.00458,0,0,0-4,4V333.0415a4.00458,4.00458,0,0,0,4,4h236a4.00427,4.00427,0,0,0,4-4V314.68262a4.00427,4.00427,0,0,0-4-4Z"
              transform="translate(-311 -112.5)"
              fill="#f9a826"
            />
            <path
              d="M517.75,371.439a1.00131,1.00131,0,0,0-1,1v7.167a1.0013,1.0013,0,0,0,1,1h240a1.001,1.001,0,0,0,1-1v-7.167a1.001,1.001,0,0,0-1-1Z"
              transform="translate(-311 -112.5)"
              fill="#ccc"
            />
            <path
              d="M517.75,394.73389a1.0013,1.0013,0,0,0-1,1v7.167a1.0013,1.0013,0,0,0,1,1h240a1.001,1.001,0,0,0,1-1v-7.167a1.001,1.001,0,0,0-1-1Z"
              transform="translate(-311 -112.5)"
              fill="#ccc"
            />
            <path
              d="M517.75,418.02881a1.0013,1.0013,0,0,0-1,1v7.1665a1.0013,1.0013,0,0,0,1,1h240a1.001,1.001,0,0,0,1-1v-7.1665a1.001,1.001,0,0,0-1-1Z"
              transform="translate(-311 -112.5)"
              fill="#ccc"
            />
            <path
              d="M517.25,464.561a1.0013,1.0013,0,0,0-1,1v7.1665a1.0013,1.0013,0,0,0,1,1h240a1.001,1.001,0,0,0,1-1V465.561a1.001,1.001,0,0,0-1-1Z"
              transform="translate(-311 -112.5)"
              fill="#ccc"
            />
            <path
              d="M517.25,487.856a1.0013,1.0013,0,0,0-1,1v7.1665a1.0013,1.0013,0,0,0,1,1h240a1.001,1.001,0,0,0,1-1V488.856a1.001,1.001,0,0,0-1-1Z"
              transform="translate(-311 -112.5)"
              fill="#ccc"
            />
            <path
              d="M517.25,511.15039a1.001,1.001,0,0,0-1,1v7.167a1.001,1.001,0,0,0,1,1h240a1.00067,1.00067,0,0,0,1-1v-7.167a1.00067,1.00067,0,0,0-1-1Z"
              transform="translate(-311 -112.5)"
              fill="#ccc"
            />
            <path
              d="M517.25,534.56055a1.001,1.001,0,0,0-1,1v7.167a1.001,1.001,0,0,0,1,1h240a1.00067,1.00067,0,0,0,1-1v-7.167a1.00067,1.00067,0,0,0-1-1Z"
              transform="translate(-311 -112.5)"
              fill="#ccc"
            />
            <path
              d="M517.25,557.85547a1.001,1.001,0,0,0-1,1v7.167a1.001,1.001,0,0,0,1,1h240a1.00067,1.00067,0,0,0,1-1v-7.167a1.00067,1.00067,0,0,0-1-1Z"
              transform="translate(-311 -112.5)"
              fill="#ccc"
            />
            <path
              d="M517.25,581.15039a1.001,1.001,0,0,0-1,1v7.167a1.001,1.001,0,0,0,1,1h240a1.00067,1.00067,0,0,0,1-1v-7.167a1.00067,1.00067,0,0,0-1-1Z"
              transform="translate(-311 -112.5)"
              fill="#ccc"
            />
            <path
              d="M517.25,604.56055a1.001,1.001,0,0,0-1,1v7.167a1.001,1.001,0,0,0,1,1h240a1.00067,1.00067,0,0,0,1-1v-7.167a1.00067,1.00067,0,0,0-1-1Z"
              transform="translate(-311 -112.5)"
              fill="#ccc"
            />
            <path
              d="M517.25,627.85547a1.001,1.001,0,0,0-1,1v7.167a1.001,1.001,0,0,0,1,1h240a1.00067,1.00067,0,0,0,1-1v-7.167a1.00067,1.00067,0,0,0-1-1Z"
              transform="translate(-311 -112.5)"
              fill="#ccc"
            />
            <path
              d="M517.25,651.15039a1.001,1.001,0,0,0-1,1v7.167a1.001,1.001,0,0,0,1,1h240a1.00067,1.00067,0,0,0,1-1v-7.167a1.00067,1.00067,0,0,0-1-1Z"
              transform="translate(-311 -112.5)"
              fill="#ccc"
            />
            <circle cx="505" cy="602" r="73" fill="#f9a826" />
            <polygon
              points="498.044 632.815 476.194 604.723 488.9 594.839 499.246 608.141 534.195 571.248 545.882 582.32 498.044 632.815"
              fill="#fff"
            />
          </svg>
          <div>
            <h3
              class="font-semibold text-gray-600 text-2xl md:text-2xl lg:text-xl"
            >
              Vos Constats
            </h3>
            <p class="text-gray-400">
              Retrouvez ici l'intégralite de vos constats, disponible en
              téléchargement.
            </p>
          </div>
        </router-link>
        <router-link
          :event="hasCommonFolder ? 'click' : ''"
          :class="{ disable: !hasCommonFolder }"
          to="/Files"
          class="bg-white shadow sm:rounded-lg my-8 md:my-0 md:w-1/2 h-72 lg:h-96 text-center mt-4 flex flex-col justify-between align-content-start p-4 md:p-8"
        >
          <svg
            id="a14aeead-7a51-49c7-9dde-c662f5733ecb"
            class="lg:w-2/3 self-start mx-auto py-2 md:py-4 w-1/2 md:w-1/3 lg:w-auto"
            data-name="Layer 1"
            viewBox="0 0 782.04441 701.88002"
          >
            <path
              d="M609.48783,100.59015l-25.44631,6.56209L270.53735,187.9987,245.091,194.56079A48.17927,48.17927,0,0,0,210.508,253.17865L320.849,681.05606a48.17924,48.17924,0,0,0,58.61776,34.58317l.06572-.01695,364.26536-93.93675.06572-.01695a48.17923,48.17923,0,0,0,34.58309-58.6178l-110.341-427.87741A48.17928,48.17928,0,0,0,609.48783,100.59015Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#f2f2f2"
            />
            <path
              d="M612.94784,114.00532l-30.13945,7.77236L278.68955,200.20385l-30.139,7.77223a34.30949,34.30949,0,0,0-24.6275,41.74308l110.341,427.87741a34.30946,34.30946,0,0,0,41.7431,24.62736l.06572-.01695,364.26536-93.93674.06619-.01707a34.30935,34.30935,0,0,0,24.627-41.7429l-110.341-427.87741A34.30938,34.30938,0,0,0,612.94784,114.00532Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#fff"
            />
            <path
              d="M590.19,252.56327,405.917,300.08359a8.01411,8.01411,0,0,1-4.00241-15.52046l184.273-47.52033A8.01412,8.01412,0,0,1,590.19,252.56327Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#f2f2f2"
            />
            <path
              d="M628.955,270.49906,412.671,326.27437a8.01411,8.01411,0,1,1-4.00241-15.52046l216.284-55.77531a8.01411,8.01411,0,0,1,4.00242,15.52046Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#f2f2f2"
            />
            <path
              d="M620.45825,369.93676l-184.273,47.52032a8.01411,8.01411,0,1,1-4.00242-15.52046l184.273-47.52032a8.01411,8.01411,0,1,1,4.00241,15.52046Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#f2f2f2"
            />
            <path
              d="M659.22329,387.87255l-216.284,55.77531a8.01411,8.01411,0,1,1-4.00242-15.52046l216.284-55.77531a8.01411,8.01411,0,0,1,4.00242,15.52046Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#f2f2f2"
            />
            <path
              d="M650.72653,487.31025l-184.273,47.52033a8.01412,8.01412,0,0,1-4.00242-15.52047l184.273-47.52032a8.01411,8.01411,0,0,1,4.00242,15.52046Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#f2f2f2"
            />
            <path
              d="M689.49156,505.246l-216.284,55.77532a8.01412,8.01412,0,1,1-4.00241-15.52047l216.284-55.77531a8.01411,8.01411,0,0,1,4.00242,15.52046Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#f2f2f2"
            />
            <path
              d="M374.45884,348.80871l-65.21246,16.817a3.847,3.847,0,0,1-4.68062-2.76146L289.5963,304.81607a3.847,3.847,0,0,1,2.76145-4.68061l65.21247-16.817a3.847,3.847,0,0,1,4.68061,2.76145l14.96947,58.04817A3.847,3.847,0,0,1,374.45884,348.80871Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#e6e6e6"
            />
            <path
              d="M404.72712,466.1822l-65.21247,16.817a3.847,3.847,0,0,1-4.68062-2.76146l-14.96946-58.04816A3.847,3.847,0,0,1,322.626,417.509l65.21246-16.817a3.847,3.847,0,0,1,4.68062,2.76145l14.96946,58.04817A3.847,3.847,0,0,1,404.72712,466.1822Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#e6e6e6"
            />
            <path
              d="M434.99539,583.55569l-65.21246,16.817a3.847,3.847,0,0,1-4.68062-2.76145l-14.96946-58.04817a3.847,3.847,0,0,1,2.76145-4.68062l65.21247-16.817a3.847,3.847,0,0,1,4.68061,2.76146l14.96947,58.04816A3.847,3.847,0,0,1,434.99539,583.55569Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#e6e6e6"
            />
            <path
              d="M863.63647,209.0517H487.31811a48.17928,48.17928,0,0,0-48.125,48.12512V699.05261a48.17924,48.17924,0,0,0,48.125,48.12507H863.63647a48.17924,48.17924,0,0,0,48.125-48.12507V257.17682A48.17928,48.17928,0,0,0,863.63647,209.0517Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#e6e6e6"
            />
            <path
              d="M863.637,222.90589H487.31811a34.30948,34.30948,0,0,0-34.271,34.27093V699.05261a34.30947,34.30947,0,0,0,34.271,34.27088H863.637a34.30936,34.30936,0,0,0,34.27051-34.27088V257.17682A34.30937,34.30937,0,0,0,863.637,222.90589Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#fff"
            />
            <path
              d="M807.00068,465.71551H616.699a8.01412,8.01412,0,1,1,0-16.02823H807.00068a8.01412,8.01412,0,0,1,0,16.02823Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#e6e6e6"
            />
            <path
              d="M840.05889,492.76314H616.699a8.01412,8.01412,0,1,1,0-16.02823H840.05889a8.01411,8.01411,0,1,1,0,16.02823Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#e6e6e6"
            />
            <path
              d="M807.00068,586.929H616.699a8.01412,8.01412,0,1,1,0-16.02823H807.00068a8.01411,8.01411,0,0,1,0,16.02823Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#e6e6e6"
            />
            <path
              d="M840.05889,613.97661H616.699a8.01412,8.01412,0,1,1,0-16.02823H840.05889a8.01412,8.01412,0,1,1,0,16.02823Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#e6e6e6"
            />
            <path
              d="M574.07028,505.04162H506.72434a3.847,3.847,0,0,1-3.84278-3.84278V441.25158a3.847,3.847,0,0,1,3.84278-3.84278h67.34594a3.847,3.847,0,0,1,3.84278,3.84278v59.94726A3.847,3.847,0,0,1,574.07028,505.04162Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#e6e6e6"
            />
            <path
              d="M574.07028,626.25509H506.72434a3.847,3.847,0,0,1-3.84278-3.84278V562.46505a3.847,3.847,0,0,1,3.84278-3.84278h67.34594a3.847,3.847,0,0,1,3.84278,3.84278v59.94726A3.847,3.847,0,0,1,574.07028,626.25509Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#e6e6e6"
            />
            <path
              d="M807.21185,330.781H666.91017a8.01411,8.01411,0,0,1,0-16.02823H807.21185a8.01411,8.01411,0,0,1,0,16.02823Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#ccc"
            />
            <path
              d="M840.27007,357.82862H666.91017a8.01411,8.01411,0,1,1,0-16.02822h173.3599a8.01411,8.01411,0,0,1,0,16.02822Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#ccc"
            />
            <path
              d="M635.85911,390.6071H506.51316a3.847,3.847,0,0,1-3.84277-3.84277V285.81706a3.847,3.847,0,0,1,3.84277-3.84277H635.85911a3.847,3.847,0,0,1,3.84277,3.84277V386.76433A3.847,3.847,0,0,1,635.85911,390.6071Z"
              transform="translate(-208.9778 -99.05999)"
              fill="#f9a826"
            />
          </svg>
          <div>
            <h3
              class="font-semibold text-gray-600 text-2xl md:text-2xl lg:text-xl"
            >
              Vos Documents
            </h3>
            <p class="text-gray-400">Consultez ou deposez vos documents</p>
          </div>
        </router-link>
      </div>
    </main>
  </div>
</template>
<script>
import NavBar from "../components/navbar";

export default {
  name: "Home",
  components: { NavBar },
  computed: {
    accountName() {
      return this.$store.getters["auth/getAccountName"];
    },
    hasCommonFolder() {
      return true;
      //return this.$store.getters["file/hasCommonFolder"];
    },
    initial() {
      const accountName = this.$store.getters["auth/getAccountName"];
      if (accountName && accountName.indexOf(" ") >= 0) {
        return (
          accountName.split(" ")[0].charAt(0).toUpperCase() +
          accountName.split(" ")[1].charAt(0).toUpperCase()
        );
      } else {
        return accountName.charAt(0);
      }
    },
  },
};
</script>

<style>
.bg-orange {
  background-color: #ed8936;
}
.disable {
  opacity: 0.5;
  pointer-events: none;
}
</style>
