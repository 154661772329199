export const endpoint = {
  //graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  //graphDriveEndpoint: "https://graph.microsoft.com/v1.0/me/drive/root/children"
  //graphDriveEndpoint:
  //  "https://graph.microsoft.com/v1.0/drives/b!obGz3j-0Q02MGeJAKV9eM8Yg8lXhNXROiqA63yKyCfqClTBHmeNiRrw-SRJWNUNQ/items/01QPL66X5NG7ICXZMB5NFIHAMEDYSFMFGO/children",
  graphSharedFolderEndpoint:
    "https://graph.microsoft.com/v1.0/me/drive/sharedWithMe",

  graphUserDriveEndpoint: "https://graph.microsoft.com/v1.0/drives/",

  userRootSite: "https://graph.microsoft.com/v1.0/sites/root",

  userSiteBase: "https://graph.microsoft.com/v1.0/sites/",

  // graphDriveEndpoint: "https://graph.microsoft.com/v1.0/me/drive/items/01JBBYZ7F6Y2GOVW7725BZO354PWSELRRZ/children"
};
