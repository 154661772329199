import FileService from "../services/files.services";

export const file = {
  namespaced: true,
  state: {
    constatFolders: [],
    commonFolderFiles: [],
    commonFolderDriveId: "",
    commonFolderItemId: "",
    constatOrderBy: "name",
    constatOrderDirection: "desc",
    fileOrderBy: "date",
    fileOrderDirection: "desc",
  },
  mutations: {
    setConstatOrderBy(state, orderBy) {
      state.constatOrderBy = orderBy;
    },
    setConstatOrderDirection(state, orderDirection) {
      state.constatOrderDirection = orderDirection;
    },
    setFileOrderBy(state, orderBy) {
      state.fileOrderBy = orderBy;
    },
    setFileOrderDirection(state) {
      if (state.fileOrderDirection == "asc") {
        state.fileOrderDirection = "desc";
      } else {
        state.fileOrderDirection = "asc";
      }
    },
    setConstatFolders(state, folders) {
      state.constatFolders = folders;
    },
    setCommonFolderFiles(state, folders) {
      state.commonFolderFiles = folders;
    },
    setCommonFolderDriveId(state, driveId) {
      state.commonFolderDriveId = driveId;
    },
    setCommonFolderItemId(state, itemId) {
      state.commonFolderItemId = itemId;
    },
  },
  actions: {
    updateConstatOrderBy({ commit }, data) {
      commit("setConstatOrderBy", data);
    },
    updateConstatOrderDirection({ commit }, data) {
      commit("setConstatOrderDirection", data);
    },
    updateFileOrderBy({ commit, state, dispatch }, data) {
      commit("setFileOrderBy", data);
      dispatch("orderFiles", {
        order: state.fileOrderBy,
        direction: state.fileOrderDirection,
      });
    },
    updateFileOrderDirection({ commit, state, dispatch }) {
      commit("setFileOrderDirection");
      dispatch("orderFiles", {
        order: state.fileOrderBy,
        direction: state.fileOrderDirection,
      });
    },
    orderFiles({ commit, state }, data) {
      //console.log("orderFiles");
      let files = state.commonFolderFiles;
      switch (data.order) {
        case "name":
          files.sort(function (a, b) {
            if (data.direction == "desc") {
              return a.name.localeCompare(b.name);
            } else {
              return b.name.localeCompare(a.name);
            }
          });
          break;
        case "date":
          files.sort(function (a, b) {
            if (data.direction == "desc") {
              return new Date(b.createdDateTime) - new Date(a.createdDateTime);
            } else {
              return new Date(a.createdDateTime) - new Date(b.createdDateTime);
            }
          });
          break;
      }

      /*
        Update the cafes state.
      */
      commit("setCommonFolderFiles", files);
    },
    fetchConstatFolders({ commit }, { username, driveId }) {
      FileService.getDriveChildren(username, driveId).then((response) => {
        response.value.map((elt) => {
          if (elt.name.startsWith("ROOTCST")) {
            FileService.getFolderFiles(username, driveId, elt.id).then(
              (response) => {
                const folders = response.value
                  .map((child) => {
                    if (child.name.startsWith("CST")) {
                      return {
                        name: child.name,
                        driveId: child.parentReference.driveId,
                        itemId: child.id,
                      };
                    }
                  })
                  .filter((elt) => {
                    return elt !== undefined;
                  });
                commit("setConstatFolders", folders);
              }
            );
          }
        });
      });

      // FileService.getUserSharedFolder(username).then((response) => {
      //   response.value.map((elt) => {
      //     if (elt.name.startsWith("ROOTCST")) {
      //       FileService.getFolderFiles(
      //         username,
      //         elt.remoteItem.parentReference.driveId,
      //         elt.id
      //       ).then((response) => {
      //         const folders = response.value
      //           .map((child) => {
      //             if (child.name.startsWith("CST")) {
      //               //console.log(child);
      //               return {
      //                 name: child.name,
      //                 driveId: child.parentReference.driveId,
      //                 itemId: child.id,
      //               };
      //             }
      //           })
      //           .filter((elt) => {
      //             return elt !== undefined;
      //           });
      //         commit("setConstatFolders", folders);
      //       });
      //     }
      //   });
      // });
    },
    fetchUserCommonFiles({ commit, state, dispatch }, { username, driveId }) {
      FileService.getDriveChildren(username, driveId).then((response) => {
        response.value.map((elt) => {
          if (elt.name.startsWith("ROOTCST")) {
            FileService.getFolderFiles(username, driveId, elt.id).then(
              (response) => {
                response.value.map((elt) => {
                  if (elt.name.startsWith("CMN_")) {
                    commit("setCommonFolderDriveId", driveId);
                    commit("setCommonFolderItemId", elt.id);
                    FileService.getFolderFiles(username, driveId, elt.id).then(
                      (response) => {
                        const folders = response.value.map((elt) => {
                          //console.log(elt);
                          return {
                            name: elt.name,
                            id: elt.id,
                            createdDateTime: elt.createdDateTime,
                            downloadUrl: elt["@microsoft.graph.downloadUrl"],
                          };
                        });
                        commit("setCommonFolderFiles", folders);
                        dispatch("orderFiles", {
                          order: state.fileOrderBy,
                          direction: state.fileOrderDirection,
                        });
                      }
                    );
                  }
                });
              }
            );
          }
        });
      });
    },
  },
  getters: {
    getConstatOrderBy(state) {
      return state.constatOrderBy;
    },
    getConstatOrderDirection(state) {
      return state.constatOrderDirection;
    },
    getFileOrderBy(state) {
      return state.fileOrderBy;
    },
    getFileOrderDirection(state) {
      return state.fileOrderDirection;
    },
    getConstatFolders(state) {
      return state.constatFolders;
    },
    getConstatFolderNumber(state) {
      return state.constatFolders.length;
    },
    getUserCommonFiles(state) {
      return state.commonFolderFiles;
    },
    getCommonFolderDriveId(state) {
      return state.commonFolderDriveId;
    },
    getCommonFolderItemId(state) {
      return state.commonFolderItemId;
    },
    hasCommonFolder(state) {
      return state.commonFolderItemId != "";
    },
  },
};
